/* .header-section {
    padding-bottom: 7px;
} */

/* NAV */
.nav .nav-link:not(.active):hover, .nav:not(.nav-pills) .nav-item.active .nav-link, .nav:not(.nav-pills) .nav-link.active {
    color: #ef6f28;
}
.navbar-vertical .nav-tabs .active.nav-link, .navbar-vertical .nav-tabs .active .nav-link{
    border-left-color: #ef6f28;
}

.text-cap {
    display: block;
    color: #1e2022;
    font-size: .8125rem;
    font-weight: 600;
    letter-spacing: .03125rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
}