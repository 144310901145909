.bg-dark {
    background-color: #f3eee7 !important;
}
/* BTN */
.btn-primary:not(label.btn), .btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-primary:not([href]), .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #ef6f28;
    border-color: #ef6f28;
}

.w-max-400 {
    max-width: 400px;
    margin: auto;
}