.table-title {
    font-size: 13px;
}

.pagination {
    color: #1A1B25;
    width: 100%;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
}
.pagination p {
    margin: 10px;
}

.pagination div {
    display: flex;
    align-items: center;
}


.pagination-buttons button {
    border: 1px solid #c9c9c9;
    background-color: #fff;
    border-radius: 6px;
    padding: 6px 10px;
    cursor: pointer;
    letter-spacing: 1px;
    color: #1A1B25;
    font-family: "Open Sans", sans-serif;
    margin: 5px;
}

.pagination-buttons button:disabled {
    color: #c9c9c9;
    border: 1px solid #c9c9c9;
    background-color: #fff;
    cursor: default;
}
