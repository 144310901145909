.box-default {
    border: 1px solid #fffdff !important;
    border-radius: 5px;
}

a li.MuiListItem-button:hover {
    background-color: rgba(0, 0, 0, 0.00) !important;
}

/* BUTTON PRIMARY */
.MuiButton-containedPrimary {
    color: rgb(255, 255, 255) !important;
    background-color: #ef6f28 !important;
    box-shadow: rgb(9 30 66 / 12%) 0px 1px 3px !important;
}

.MuiButton-outlinedSizeSmall{
    border: 1px solid #ef6f28 !important;
    color:#ef6f28 !important;
}

/* BUTTON SECONDARY */
.MuiButton-textSecondary {
    color: rgba(0, 0, 0, 0.87) !important;
    background-color: transparent !important;
}

.MuiButton-textPrimary {
    color: #ef6f28 !important;
}

/* checked */
.MuiSwitch-colorPrimary.Mui-checked {
    color: #ef6f28 !important;
}

.MuiSwitch-track {
    background: #ef6f28 !important;
}

/* Link */
.MuiTypography-colorPrimary {
    color: #ef6f28 !important;
}

/* Menu */
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
    background: rgba(239, 111, 40, 0.2) !important;
}

.p-20 {
    padding: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.p-0 {
    padding: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.ml-2 {
    margin-left: 20px;
}

.mr-2 {
    margin-right: 20px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.w-100 {
    width: 100% !important;
}

.MuiTableCell-head {
    font-size: 12px !important;
}

.alert-success {
    background-color: #3edb93;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 6px !important;
    color: #000;
}

.alert-danger {
    background-color: #f27ba7;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 6px !important;
    color: #000;
}

a.MuiButtonBase-root:hover {
    color: #000 !important;
}

.MuiTabs-flexContainer {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #dcdbdc
}

.font-w-100 {
    font-weight: 100;
}

.MuiContainer-root{
    background-color: #ffffff;
}

.box-default {
    border: 1px solid #dcdbdc !important;
    border-radius: 5px;
}

@media (min-width: 1280px) {
    .MuiContainer-maxWidthLg {
        max-width: 1700px!important;
    }
}
.MuiDataGrid-columnHeaders{
    background-color: rgb(241, 242, 246);
    height: 50px;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root.Mui-disabled{
    opacity: 0.2;
}
